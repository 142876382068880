import * as React from "react";

export default function Join() {
  return (
    <>
      <div>
        <p class="nav-link">
          &laquo; <a href="/">Tuze Home</a>
        </p>
        <section>
          <h4>The Tuze App</h4>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h5>
            You&rsquo;ve been invited to a pool on Tuze! That&rsquo;s cool.
          </h5>
          <p>
            But you need to open this link on your iOS or Android phone for the
            invite to work.
          </p>
          <p>
            Please close this window and open the invite link on your phone.
            <br />
            (Albert thanks you in advance. 🐵🙌)
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </section>
      </div>
    </>
  );
}
