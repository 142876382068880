export default function LandingPage() {
  return (
    <div>
      <header>
        <h1>
          Data-Driven, AI-Enhanced Sports Predictions That Are At Least 62.5%
          Correct*
        </h1>
        <h1 className="tuze_highlight">
          Give TUZE a try to predict the future in the world&rsquo;s most
          popular sports
        </h1>

        <h1 className="tuze_highlight">⚾ 🏈 ⚽ 🏀 🏒 + more</h1>

        <h3>
          Get to know Albert™, our (AI chimpanzee) sports data analyst. Take his
          writeups at face value, or fine-tune predictions by highlighting the
          stats you think are most important to a current prediction.
        </h3>
        <h4>&darr;</h4>
        <code>
          <img
            src={"/images/i14/albert.png"}
            alt={"cantankerous chimpanzee"}
            className="albert-inset"
          />
          &ldquo;Finally we have Manchester City visiting Burnley, folks, isn't
          this a treat? Looking at the goalkeeper situation, City's Ederson and
          Steffen bring strong stats from the last season compared to Muric at
          Burnley, with Peacock-Farrell's performance fairly reminiscent of a
          bird attempting trigonometry.
          <br />
          <br />
          Our top performers from last season? Erling Haaland spearheading
          City's offense, flashing a superfluous 36 goals and 60 shots on goal;
          no wonder why the club's record was a whopping 28-5-5. For Burnley,
          Brownhill and O'Shea offer something to ponder on with impressive
          rating and decent strikes, albeit these aren't winning matches on
          their own.
          <br />
          <br />
          Historically, it's reminiscent of a Sisyphean tragedy for Burnley, so
          if you're after hot betting tips, it's not rocket science. Burnley got
          zilch last season, hasn't seen a victory against Manchester City in
          recent memory, and the payout data leans heavily towards City.&rdquo;
          <br />
          <br />
          Albert&rsquo;s Predicto: Manchester City by 3
          <br />
          <br />
          Actual 11 August 2023:{" "}
          <a
            href="https://www.bbc.com/sport/football/66402155"
            target="_burmci"
          >
            BUR 0 &ndash; MCI 3
          </a>
        </code>
        <h3>Team up with Albert to tackle the unknown.</h3>
      </header>
      <section>
        <a className="cta" href="https://sports.tuze.ai">
          Get Started &raquo;
        </a>
      </section>
    </div>
  );
}
