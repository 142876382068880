import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TagManager from "react-gtm-module";
import "../css/app.css";
import LandingPage from "../pages/LandingPage";
import FooterNav from "../pages/Footer";
import ScrollToTop from "./ScrollToTop";
import Join from "./Join";

const tagManagerArgs = {
  gtmId: "GTM-KGJ3WLG",
  auth: "Bs3yXYDg0-8a_9ch3vFT0w",
  preview: "env-8",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<FooterNav />}>
          <Route index element={<LandingPage />} />
          <Route path="join" element={<Join />} />
          <Route path="join/:id" element={<Join />} />
          <Route path="pro-basketball-join" element={<Join />} />
          <Route path="pro-basketball-join/:id" element={<Join />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
